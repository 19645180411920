import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Pill = styled.span`
  font-family: ${({ theme }) => theme.font.heading};
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -.5px;
  line-height: 1.55em;
  display: inline-block;
  padding: 2px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  margin-right: 15px;
  color: white;
  @media (min-width: ${({theme}) => theme.breakpoints.s}) {
    font-size: 20px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.l}) {
    margin-bottom: 25px;
  }
  &.green {
    background-color: ${({ theme }) => theme.colors.darkGreen};
  }
  &.red {
    background-color: ${({ theme }) => theme.colors.darkMagenta};
  }
  &.yellow {
    background-color:${({ theme }) => theme.colors.darkYellow};
  }
  &.orange {
    background-color: ${({ theme }) => theme.colors.darkOrange};
  }
  &.blue {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
`

const CategoryPill = ({
  uid,
  name,
  color,
 }) => {
  return (
    <Link to={`/${uid}`}>
      <Pill className={color}>{name}</Pill>
    </Link>
  )
}

export default CategoryPill