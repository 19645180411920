import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledCard = styled.div`
  background-color: white;
  border-radius: 17px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Title = styled.div`
  text-align: center;
  h3 {
    font-size: 19px;
    font-weight: 800;
    margin: 0;
  }
  &.orange {
    h3 {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.green {
    h3 {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    h3 {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.red {
    h3 {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  &.yellow {
    h3 {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    h3 {
      font-size: 28px;
    }
  }
`

const CategoryCard = ({ color, title, link }) => {
  return (
    <Link to={link}>
      <StyledCard>
        <Title className={color}>
          <h3>{title}</h3>
        </Title>
      </StyledCard>
    </Link>
  )
}

export default CategoryCard
