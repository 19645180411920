import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { BsArrowRight } from 'react-icons/bs'

const StyledCard = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 22px;
  width: 100%;
  height: fit-content;
`
const TopLayer = styled.div`
  border-radius: 17px;
  height: fit-content;
  overflow: hidden;
  span,
  svg {
    color: ${({ theme }) => theme.colors.whyOrange};
  }
`
const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  h3 {
    color: ${({ theme }) => theme.colors.greyDark};
    font-size: 20px;
    line-height: 21px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: -0.2px;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  padding: 20px 9px 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    height: auto;
  }
`
const ReadMore = styled.div`
  display: flex;
  align-items: center;
  span {
    font-weight: 600;
    font-size: 14px;
    margin-right: 5px;
  }
`

const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: fit-content;
  min-height: 110px;
  p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    color: black;
    font-size: 17px;
    line-height: 27px;
    letter-spacing: -0.2px;
  }
`
const Category = styled.p`
  width: fit-content;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
  font-weight: 500;
  &.orange {
    color: ${({ theme }) => theme.colors.whyOrange};
    border: 1px solid ${({ theme }) => theme.colors.whyOrange};
  }
  &.green {
    color: ${({ theme }) => theme.colors.whyGreen};
    border: 1px solid ${({ theme }) => theme.colors.whyGreen};
  }
  &.blue {
    color: ${({ theme }) => theme.colors.whyBlue};
    border: 1px solid ${({ theme }) => theme.colors.whyBlue};
  }
  &.red {
    color: ${({ theme }) => theme.colors.whyMagenta};
    border: 1px solid ${({ theme }) => theme.colors.whyMagenta};
  }
  &.yellow {
    color: ${({ theme }) => theme.colors.whyYellow};
    border: 1px solid ${({ theme }) => theme.colors.whyYellow};
  }
`

const MostReadCard = ({ color, title, content, link, category }) => {
  return (
    <StyledCard className="large">
      <Link to={link}>
        <TopLayer className={color}>
          <Info>
            <Category className={color}>{category}</Category>
            <Title>
              <h3>{title}</h3>
            </Title>
            <TextWrapper>
              <p>{content}</p>
            </TextWrapper>
            <ReadMore>
              <span>Read full article</span>
              <BsArrowRight />
            </ReadMore>
          </Info>
        </TopLayer>
      </Link>
    </StyledCard>
  )
}

export default MostReadCard
