import React from 'react'
import styled from 'styled-components'
import Container from '../primary/Container'
import CategoryPill from '../elements/CategoryPill'
import CategoryCard from '../elements/CategoryCard'

const StyledBanner = styled.div`
  min-height: ${({ small }) => (small ? '330px' : '425px')};
  background-position: center;
  background-size: cover;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: ${({ isWithSubcats }) => (isWithSubcats ? '75px' : 0)};
  @media (min-width: 1600px) {
    .container {
      max-width: 1440px !important;
      padding: 0 !important;
    }
  }
  a {
    color: white;
  }
  &.orange {
    background: ${({ theme }) => theme.colors.whyOrange};
  }
  &.green {
    background: ${({ theme }) => theme.colors.whyGreen};
  }
  &.blue {
    background: ${({ theme }) => theme.colors.whyBlue};
  }
  &.yellow {
    background: ${({ theme }) => theme.colors.whyYellow};
  }
  &.red {
    background: ${({ theme }) => theme.colors.whyMagenta};
  }
`
const Blurb = styled.div`
  padding: ${({ isWithSubcats }) =>
    isWithSubcats ? '130px 20px 40px' : '0 20px 40px'};
  display: flex;
  height: ${({ isWithSubcats }) => (isWithSubcats ? 'auto' : '286px')};
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  h1 {
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 15px;
    line-height: 1.1em;
    color: #fff;
    opacity: 1;
  }
  p {
    font-family: ${({ theme }) => theme.font.body};
    font-weight: 500;
    font-size: 19px;
    letter-spacing: 0.7px;
    line-height: 1.55em;
    color: #fff;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: ${({ isWithSubcats }) =>
      isWithSubcats ? '130px 20px 40px' : '0 20px 90px'};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    h1 {
      font-size: 63px;
    }
    p {
      font-size: 20px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    h1 {
      font-size: 70px;
    }
  }
  span {
    &.orange {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
    &.green {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
    &.blue {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
    &.red {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    &.yellow {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
`
const Flex = styled.div`
  display: grid;
  margin: 0 auto;
  padding: 0;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-row-gap: 30px;
    grid-column-gap: 35px;
  }
  h3 {
    font-size: 20px;
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      font-size: 23px;
    }
  }
`
const CatWrap = styled.div`
  margin: 0 auto;
  max-width: 800px;
  width: 90%;
  padding: 40px 0 0;
  height: 60px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 60px 0 0;
    height: 120px;
  }
`
const CategoryBanner = ({
  name,
  description,
  color,
  subcat,
  subcategories,
  parentUid,
  parentName,
  category
}) => {
  const isWithSubcats = subcategories?.length > 0
  return (
    <StyledBanner
      small={subcat}
      className={color}
      isWithSubcats={isWithSubcats}
    >
      {!isWithSubcats && (
        <CatWrap>
          {parentUid && (
            <CategoryPill uid={parentUid} color={color} name={parentName} />
          )}
        </CatWrap>
      )}

      <Blurb className={color} isWithSubcats={isWithSubcats}>
        <h1>{name}</h1>
        <p>{description}</p>
      </Blurb>
      {!subcat && (
        <Container width="1175px">
          <Flex>
            {subcategories.map((node) => (
              <CategoryCard
                key={node.uid}
                color={color}
                title={node.data.name}
                link={`/${node.uid}`}
              />
            ))}
          </Flex>
        </Container>
      )}
    </StyledBanner>
  )
}

export default CategoryBanner
