import React from 'react'
import { Link } from 'gatsby'
import styled, { ThemeContext } from 'styled-components'
import Img from 'gatsby-image'
import { BsArrowRight } from 'react-icons/bs'

const StyledCard = styled.div`
  position: relative;
  padding: 7px;
  background-color: white;
  border-radius: 22px;
`
const TopLayer = styled.div`
  border-radius: 17px;
  overflow: hidden;
  height: fit-content;
`
const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  h3 {
    font-size: 19px;
    color: ${({ theme }) => theme.colors.greyDark};
    font-weight: 600;
    &.orange {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
    &.green {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
    &.blue {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
    &.red {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    &.yellow {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    h3 {
      font-size: 21px;
    }
  }
`
const ImageWrap = styled.div`
  border: none;
  .gatsby-image-wrapper {
    height: 220px;
  }
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      aspect-ratio: 16/9;
    }
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  padding: 20px 9px 10px;
`
const ReadMore = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
  }
`

const SubcatCard = ({ color, title, image, link }) => {
  return (
    <StyledCard className="large">
      <Link to={link}>
        <TopLayer>
          <ImageWrap>{image?.fluid && <Img fluid={image?.fluid} />}</ImageWrap>
          <Info>
            <Title>
              <h3 className={color}>{title}</h3>
            </Title>
          </Info>
        </TopLayer>
      </Link>
    </StyledCard>
  )
}

export default SubcatCard
