import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Container from '../components/primary/Container'
import SEO from '../components/primary/SEO'
import ArticleCard from '../components/elements/CardWithImage'
import Card from '../components/elements/CardWithTitle'
import CategoryBanner from '../components/page-sections/CategoryBanner'
import Layout from '../components/primary/Layout'
import { node } from 'prop-types'
import ContentContainer from '../components/primary/Container'
import MostReadCard from '../components/elements/MostReadCard'
import SubcatCard from '../components/page-sections/SubcatCard'

const ArticleFlex = styled.div`
  display: grid;
  margin: 0 auto;
  margin-top: -125px;
  margin-bottom: 45px;
  padding: 0 10px;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-row-gap: 70px;
  grid-column-gap: 25px;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    padding: 0;
  }
`

// const CardWrap = styled.div`
//   padding: 90px 0 110px;
//   h2 {
//     font-size: 46px;
//     font-weight: 800;
//     margin: 0 auto 50px;
//     text-align: center;
//   }
//   &.orange {
//     background-color: rgba(242, 132, 22, 0.1);
//     h2 {
//       color: ${({ theme }) => theme.colors.whyOrange};
//     }
//   }
//   &.green {
//     background-color: rgba(141, 145, 64, 0.6);
//     h2 {
//       color: ${({ theme }) => theme.colors.whyGreen};
//     }
//   }
//   &.blue {
//     background-color: rgba(14, 77, 128, 0.4);
//     h2 {
//       color: ${({ theme }) => theme.colors.whyBlue};
//     }
//   }
//   &.yellow {
//     background-color: rgba(245, 185, 6, 0.4);
//     h2 {
//       color: ${({ theme }) => theme.colors.whyYellow};
//     }
//   }
//   &.red {
//     background-color: rgba(182, 36, 98, 0.4);
//     h2 {
//       color: ${({ theme }) => theme.colors.whyRed};
//     }
//   }
//   @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
//   }
// `
const Background = styled.div`
  background-color: #fafafa;
  padding: 50px 0;
`
const Wrapper = styled.div`
  margin-top: 0;
  padding-top: 55px;
  background-color: rgb(247, 247, 247);
  .container {
    margin: 0 100px;
    padding: 0;
    @media (min-width: 1600px) {
      margin: 0 auto;
      max-width: 1440px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
      margin: 0 50px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 800;
    margin-bottom: 40px;
    &.orange {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
    &.green {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
    &.blue {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
    &.red {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    &.yellow {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-top: 80px;
  }
`

const ArticleWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  padding-bottom: 60px;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
`
const Category = ({
  data: { category, articles },
  pageContext: { subcategories }
}) => {
  const favorites = category.data.favorites
  const color = category.data.color !== 'none' ? category.data.color : 'blue'
  const meta = category.data.meta_description
  const description = category.data.description
  return (
    <Layout color={color}>
      <SEO
        title={`${category.data.name}`}
        description={meta ? meta : description}
      />
      <CategoryBanner
        name={category.data.name}
        description={category.data.description}
        color={color}
        subcategories={subcategories}
        category={category.data.name}
      />
      {subcategories.length === 0 && (
        <Background>
          <Container width="986px">
            <ArticleFlex>
              {articles.edges.map(({ node }) => (
                <ArticleCard
                  key={node.uid}
                  color={category.data.color}
                  title={node.data.title}
                  image={node.data.featured_image}
                  link={`/stories/${node.uid}`}
                />
              ))}
            </ArticleFlex>
          </Container>
        </Background>
      )}
      {favorites[0].article.document ? (
        <Wrapper>
          <ContentContainer width="100%">
            <h2 className={color}>Most read answers...</h2>
            <ArticleWrap>
              {favorites.map((node) => (
                <MostReadCard
                  key={node.article.uid}
                  color={color}
                  title={node.article.document?.data.title}
                  link={`/stories/${node.article.uid}`}
                  content={node.article.document?.data.excerpt.text}
                  category={
                    node.article.document?.data.subcategories[0]?.subcategory
                      ?.document?.data?.name
                  }
                />
              ))}
            </ArticleWrap>
          </ContentContainer>
        </Wrapper>
      ) : null}
    </Layout>
  )
}

export default Category

export const CategoryQuery = graphql`
  query CategoryQuery($uid: String) {
    articles: allPrismicArticle(
      filter: {
        data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } }
      }
      sort: { order: DESC, fields: data___created }
    ) {
      edges {
        node {
          uid
          data {
            title
            created
            categories {
              category {
                uid
                slug
              }
            }
            subcategories {
              subcategory {
                document {
                  ... on PrismicSubcategory {
                    id
                    data {
                      name
                      image {
                        url
                      }
                    }
                  }
                  ... on PrismicCategory {
                    data {
                      color
                      name
                    }
                  }
                }
              }
            }
            featured_image {
              alt
              fluid(maxWidth: 280) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    category: prismicCategory(uid: { eq: $uid }) {
      data {
        description
        meta_description
        name
        color

        favorites {
          article {
            uid
            document {
              ... on PrismicArticle {
                data {
                  title
                  subcategories {
                    subcategory {
                      document {
                        ... on PrismicSubcategory {
                          id
                          data {
                            name
                            image {
                              url
                              fluid(maxWidth: 900) {
                                ...GatsbyPrismicImageFluid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          id
                          data {
                            name
                          }
                        }
                      }
                    }
                  }
                  excerpt {
                    text
                  }
                  body {
                    ... on PrismicArticleBodyQuote {
                      primary {
                        text {
                          raw
                        }
                      }
                      slice_type
                    }
                    ... on PrismicArticleBodyText {
                      primary {
                        highlight_color
                        text {
                          raw
                        }
                      }
                      slice_type
                    }
                    ... on PrismicArticleBodyImage {
                      slice_type
                      items {
                        element {
                          alt
                          fluid(maxWidth: 900) {
                            ...GatsbyPrismicImageFluid
                          }
                        }
                      }
                    }
                    ... on PrismicArticleBodyCarousel {
                      slice_type
                      items {
                        image {
                          fluid(maxWidth: 900) {
                            ...GatsbyPrismicImageFluid
                          }
                        }
                      }
                    }
                  }
                  featured_image {
                    alt
                    fluid(maxWidth: 280) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
