import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { BsArrowRight } from 'react-icons/bs'

const StyledCard = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  width: 90%;
  &.orange {
    h3,
    svg {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.green {
    h3,
    svg {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    h3,
    svg {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.red {
    h3,
    svg {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  &.yellow {
    h3,
    svg {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  :hover {
    svg {
      opacity: 1;
    }
  }
`
const Title = styled.div`
  h3 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.greyDark};
    font-weight: 600;
    margin: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    h3 {
      font-size: 23px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    //overflow: hidden;
    //text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-line-clamp: 1;
    //-webkit-box-orient: vertical;
  }
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
  }
`
const ReadMore = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 25px;
    height: 25px;
    opacity: 0;
    transition: all 0.3s ease;
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      display: block;
    }
  }
`

const CardWithTitle = ({ color, title, link }) => {
  return (
    <Link to={link}>
      <StyledCard className={color}>
        <Info>
          <Title>
            <h3>{title}</h3>
          </Title>

          <ReadMore>
            <BsArrowRight />
          </ReadMore>
        </Info>
      </StyledCard>
    </Link>
  )
}

export default CardWithTitle
